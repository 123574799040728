import mobile_dev from "../../src/images/mobile-dev.jpg";
import web_dev from "../../src/images/web-dev.jpg";
import data_analytics from "../../src/images/da-dev2.jpg";
import blockchain_dev from "../../src/images/blockchain.png";

export const lushRide_intro = [
  {
    media_id: 1,
    media_src: null,
    media_title: "LushRide",
    media_desc: `Our flagship product, LUSHRIDE, is a car-hailing service that raises
                  e-riders consciousness on their carbon footprint (COe) during
                  driving sessions. We're currently leveraging the XRPL robust chain
                  to create NFT tokens for users' carbon footprints, with the aim of
                  giving them a choice of monetizing or analysing their data for
                  global climate change mitigation.`,
  },
];
export const demo_media = [
  {
    media_id: 0,
    media_src: "https://www.youtube.com/embed/eHuHLZx5Rio",
    media_title: "LushRide Web2 MVP Demo",
    media_desc: "",
  },
  {
    media_id: 1,
    media_src: "https://www.youtube.com/embed/ZxuGu63V7WY",
    media_title: "LushRide-XRPL Integration",
    media_desc: "",
  },
];
export const services_image = [
  {
    media_id: 0,
    media_name: mobile_dev,
    media_title: "Mobile Development",
    media_desc: "",
  },
  {
    media_id: 1,
    media_name: web_dev,
    media_title: "Web Development",
    media_desc: "",
  },
  {
    media_id: 2,
    media_name: data_analytics,
    media_title: "Data Analytics",
    media_desc: "",
  },
  {
    media_id: 3,
    media_name: blockchain_dev,
    media_title: "Blockchain Development",
    media_desc: "",
  },
];

export const variants = {
  variations: [
    {
      variation_id: 1,
      variation_name: "210kr",
      price: "210",
      reward_text: "200kr. Price",
      reward_description:
        "Med et gavekort til Sendentanke.dk kan du vælge mellem gavekort til hundredevis af butikker og oplevelser ét sted.",
    },
    {
      variation_id: 2,
      variation_name: "400kro",
      price: "400",
      reward_text: "400 Price",
      reward_description: "Earn a reward",
    },
  ],
};
